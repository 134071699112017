import data from '../data/dat.json';
import axios from 'axios'
import SimpleDateTime from 'react-simple-timestamp-to-date';
import { Buffer } from "buffer";

export async function getFiles(response) {

    const res = await axios.get(data.url + '/api/getFiles/' + '1');

    // console.log(res.data);


    for (var i = 0; i < res.data.length; i++) {

        if (res.data[i].d_state == 0) {
            //   console.log('En attente');
            res.data[i].d_state = 'En attente';
        }
        else if (res.data[i].d_state == 1) {
            //  console.log('Validé');
            res.data[i].d_state = 'Validé';
        }
        else if (res.data[i].d_state == 2) {
            //  console.log('Assigné');
            res.data[i].d_state = 'Assigné';
        }
    }



    for (var i = 0; i < res.data.length; i++) {

        var dateTimeProperties = { 'dateSeparator': '/', 'dateFormat': 'DMY', 'showTime': '0', 'children': res.data[i].d_creation }

        res.data[i].d_creation = SimpleDateTime(dateTimeProperties);

    }

    return res.data;

}

export async function getWaitingFiles(response) {

    const res = await axios.get(data.url + '/api/getWaitingFiles/' + '1');

    // console.log(res.data);

    for (var i = 0; i < res.data.length; i++) {

        var dateTimeProperties = { 'dateSeparator': '/', 'dateFormat': 'DMY', 'showTime': '0', 'children': res.data[i].d_creation }

        res.data[i].d_creation = SimpleDateTime(dateTimeProperties);

    }

    return res.data;

}

export async function addFileTagLink(tagLink) {


    const res = await axios.post(data.url + '/api/addFileTagLink', tagLink);

    console.log(res);

    return res
}

export async function getTagLinkFile(id) {

    console.log(id);

    const res = await axios.get(data.url + '/api/getTagLinkFile/' + id);

    // console.log(res.data);


    return res.data;

}

export async function updateStatus(file) {

   

    const res = await axios.get(data.url + '/api/ChangeFileStatus/',file);

    // console.log(res.data);


    return res.data;

}


export async function getRelativeFiles(id) {

    const res = await axios.get(data.url + '/api/getRelativeFiles/' + '1' + '/' + id);

    // console.log(res.data);

    for (var i = 0; i < res.data.length; i++) {

        var dateTimeProperties = { 'dateSeparator': '/', 'dateFormat': 'DMY', 'showTime': '0', 'children': res.data[i].d_creation }

        res.data[i].d_creation = SimpleDateTime(dateTimeProperties);

    }

    return res.data;

}

export async function deleteFileDB(id) {

console.log("suppress");

    const res = await axios.post(data.url + '/api/deleteFile', id);


    return res
}

export async function getImage(name, type) {



    axios.get(data.url + '/api/getFileList/' + name + '/' + type, { responseType: 'arraybuffer' })
        .then(response => {
            // Crée un objet Blob à partir des données binaires reçues

            console.log(response.data);

            console.log(type);


            if (type == 'png' || type == 'jpg') {
                const blob = new Blob([response.data], { type: 'image/png' }); // Remplacez "image/png" par le type de contenu approprié (ex: "image/jpeg" pour JPEG)
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = name;

                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
            }
            else if (type == 'pdf') {
                const blob = new Blob([response.data], { type: 'application/pdf' }); // Remplacez "image/png" par le type de contenu approprié (ex: "image/jpeg" pour JPEG) 
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = name;

                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
            }
            else {
                const blob = new Blob([response.data], { type: 'image/png' }); // Remplacez "image/png" par le type de contenu approprié (ex: "image/jpeg" pour JPEG) 
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = name;

                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
            }





            // Crée une URL à partir du blob


        })
        .catch(error => console.error(error));





    // return res;

}











