import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo/braives-logo/braives.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { firebase_app, googleProvider, facebookProvider, twitterProvider, githubProvider, Jwt_token } from "../data/config";
import { useAuth0 } from '@auth0/auth0-react'
import { Login, LOGIN, YourName, Password, RememberMe, LoginWithAuth0, LoginWithJWT } from '../constant';
import { useNavigate } from 'react-router-dom';
import data from '../data/dat.json';
import axios from 'axios'


const Signin = () => {

    const { loginWithRedirect } = useAuth0()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );

    useEffect(() => {
        if (value !== null)
            localStorage.setItem('profileURL', value);
        else
            localStorage.setItem('profileURL', man);
    }, [value]);

    const loginAuth = async () => {

        const res = await axios.get(data.url + '/api/auth/' + password + '/' + email + '/' + 1);




        if (res.data) {



console.log(res.data);

            //   const res1 = await axios.get(data.url + '/api/insertToken/' + res.data[0].Id_t_user + '/' + Jwt_token);
            localStorage.setItem('token', res.data);




            navigate(`${process.env.PUBLIC_URL}/archives/documents`);
        }
        else {
            setTimeout(() => {
                toast.error("Utilisateur ou mot de passe incorrect.");
            }, 200);
        }




        //setValue(man);
        // localStorage.setItem('token', Jwt_token);
        //navigate(`${process.env.PUBLIC_URL}/dashboard/default`);


    }

    



    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                            <img src={logo} alt="" /></div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                <h4>Administration de la bibliothèque virtuelle de Braives</h4>
                                                   
                                                  
                                                </div>
                                                <form className="theme-form" >
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">Login</label>
                                                        <input className="form-control" type="email" name="email"
                                                            value={email}
                                                            onChange={e => setEmail(e.target.value)}
                                                            placeholder="Adresse mail"
                                                        />

                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Mot de passe</label>
                                                        <input className="form-control" type="password" name="Mot de passe"
                                                            value={password}
                                                            onChange={e => setPassword(e.target.value)} />

                                                    </div>
                                                    <div className="checkbox p-0">
                                                        <input id="checkbox1" type="checkbox" />
                                                        <label htmlFor="checkbox1">Se souvenir</label>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        <button className="btn btn-primary" type="button" onClick={() => loginAuth()} >Connexion</button>
                                                    </div>



                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default Signin;